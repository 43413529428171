<template>
  <b-modal
    id="profile-img-uploader-modal"
    title="Upload Profile Picture"
    @hidden="reset"
  >
    <b-overlay :show="isProcessing">
      <div class="text-center">
        <vue-avatar
          ref="vueavatar"
          :width="128"
          :height="128"
          :scale="Number(scale)"
          :border-radius="200"
          @vue-avatar-editor:image-ready="onImageReady"
        />
        <br>
        <b-form-group
          v-if="form.image"
          class="w-50 text-left mx-auto"
          label="Zoom:"
        >
          <b-form-input
            v-model="scale"
            type="range"
            :min="1"
            :max="3"
            :step="0.02"
          />
        </b-form-group>
        <small
          v-if="error"
          class="text-danger"
        >
          Please upload an image
        </small>
      </div>
    </b-overlay>
    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="isProcessing"
        @click="saveClicked"
      >
        Upload
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BModal, BButton, BFormInput, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import { VueAvatar } from 'vue-avatar-editor-improved'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BFormGroup,
    BOverlay,
    VueAvatar,
  },
  data() {
    return {
      form: {
        image: '',
      },
      error: false,
      scale: 1,
      isProcessing: false,
    }
  },
  methods: {
    onImageReady() {
      this.form.image = this.$refs.vueavatar.getImage().toDataURL()
    },
    saveClicked() {
      if (!this.form.image) {
        this.error = true
        return
      }
      this.isProcessing = true
      const img = this.$refs.vueavatar.getImageScaled()
      this.form.thumbnail = img.toDataURL()

      this.$emit('isProcessing')
      useApollo.users.updateUserAvatar({
        input: this.form,
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.uploadUserAvatar.message,
          },
        })
      }).finally(() => {
        this.$bvModal.hide('profile-img-uploader-modal')
        this.isProcessing = false
        this.$emit('success')
      })
    },
    reset() {
      this.form = { image: '' }
      this.scale = 1
    },
  },
}
</script>
