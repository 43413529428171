<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <profile-header :header-data="profileData" />
    <!-- profile info  -->
    <section id="profile-info">
      <general-information :user-uuid="profileData.uuid" />
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import ProfileHeader from './components/Header.vue'
import GeneralInformation from './components/GeneralInformation.vue'

// import Setting from './components/Setting.vue'
export default {
  components: {
    ProfileHeader,
    GeneralInformation,
    // Setting,
  },
  props: {
    userInfo: {
      type: [Object, String],
      default: () => null,
    },
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    profileData() {
      return this.userInfo ?? getUserData()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
