<template>
  <b-card
    class="profile-header mb-2"
    :img-src="coverImg"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile picture -->
    <div class="position-relative">
      <div
        v-if="isProfilePage"
        class="profile-img-container d-flex align-items-center"
      >
        <div class="profile-img text-center pt-2">
          <feather-icon
            icon="UploadIcon"
            size="72"
            color="#333"
          />
          <span class="text-black">
            Upload image
          </span>
        </div>
      </div>
      <div class="profile-img-container image d-flex align-items-center">
        <b-overlay :show="isProcessing">
          <div :class="{'profile-img-wrapper': isProfilePage}">
            <div
              v-b-modal.profile-img-uploader-modal
              class="profile-img image overflow-hidden"
            >
              <avatar
                :image="avatar ? getAvatar(avatar, 1) : getAvatar(headerData.avatar)"
                :rounded="true"
              />
            </div>
          </div>
        </b-overlay>

        <!-- profile title -->
        <div class="profile-title ml-3">
          <div class="d-flex">
            <h2
              v-if="!editName"
              class="text-white user-name"
            >
              {{ headerData.name }}
            </h2>
            <div
              v-if="self"
              class="d-flex"
            >
              <input
                v-if="editName"
                v-model="headerData.name"
                class="form-control bg-transparent border-primary border-2 text-white font-weight-bold"
                style="font-size:20px; border-width:2px;"
              >
              <i
                v-if="editName"
                class="fa fa-2x fa-check text-success px-2 cursor-pointer"
                @click="updateUserName"
              />
              <i
                v-if="!editName"
                class="fa fa-2x fa-pencil edit-icon cursor-pointer text-white ml-1"
                @click="editName=true"
              />
            </div>
          </div>
          <p class="text-white">
            {{ headerData.designation || 'User' }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                role="presentation"
                active
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">About</span>
                <feather-icon
                  icon="RssIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
            </template>

            <!-- edit buttons -->
            <!-- <template #tabs-end>
              <b-button
                variant="primary"
                class="ml-auto"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">Edit</span>
              </b-button>
            </template> -->
            <!-- edit buttons -->
          </b-tabs>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
    <image-uploader
      v-if="isProfilePage"
      @isProcessing="() => { isProcessing = true }"
      @success="()=>{
        isProcessing = false
      }"
    />
  </b-card>
</template>

<script>
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Avatar from '@/views/common/components/Avatar.vue'
import EventBus from '@/event-bus'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import ImageUploader from './ImageUploader.vue'

export default {
  components: {
    BCard,
    BTabs,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BOverlay,
    ImageUploader,
    Avatar,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      coverImg: require('@/assets/images/pages/study.jpg'),
      avatar: null,
      isProcessing: false,
      editName: false,
      userData: getUserData(),

    }
  },
  computed: {
    isProfilePage() {
      return this.$route.name === 'pages-profile'
    },
    self() {
      return this.userData.uuid === this.headerData.uuid
    },
  },
  mounted() {
    EventBus.$on('updated-avatar', avatar => {
      this.avatar = avatar
      this.isProcessing = false
    })
  },
  methods: {
    updateUserName() {
      useApollo.users.updateUserProfile({ input: { name: this.headerData.name } }).then(() => {
        this.showSuccess('User name updated successfully')
      }).finally(() => {
        this.editName = false
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.text-black {
  color: #101010;
}
</style>

<style lang="scss">
.vicp-wrap {
  a {
    border: 1px solid !important;
    border-radius: 8px !important;
  }
}
.vicp-icon5,
.vicp-icon6 {
  background-color: #28c76f !important;
}

</style>
